import React from "react"
import { graphql } from 'gatsby'
import loadable from "@loadable/component"

import SEO from "../components/seo"
import FadeIn from "../components/Animated/FadeIn"
import HSTextBanner from "../components/HSTextBanner"
const CaseStudy = loadable(() => import('../components/caseStudy'))
const SplitText = loadable(() => import("../components/Animated/SplitText"))

const WorkPage = ({ data }) => (
  <FadeIn>
    <SEO
      title="Work | See Our Work"
      description="Some from scratch, others reborn, these are the brands we've helped launch."
    />
    <HSTextBanner containerClass="work-page-container">
      <SplitText delay={1.8}>
        Deep strategic thought. Countless conversations. And a lot of emotion. It’s how we designed these brands for really long, interesting lives.
      </SplitText>
    </HSTextBanner>
    {data.contentfulCaseStudiesList.caseStudies.map(caseStudy => {
      return (
        <CaseStudy
          caseStudy={caseStudy}
          id={caseStudy.id}
          key={`case-study-${caseStudy.id}`}
        />
      )
    })}
  </FadeIn>
)

export const query = graphql`
query WorkPageQuery {
  contentfulCaseStudiesList(name: {eq: "Work Page Case Studies"}) {
    caseStudies {
      id
      isLive
      name
      slug
      textColor
      noWrap
      client {
        id
        name
      }
      serviceTypes {
        title
       }
      introResult
      featuredImages {
        images {
          title
          gatsbyImageData(quality: 70)
          file {
            details {
              size
              image {
                height
                width
              }
            }
          }
        }
      }
    }
  }
}

`

export default WorkPage
